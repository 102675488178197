import React from 'react';

import Iframe from 'components/Iframe';

function HotTourWidget({ className }) {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://www.ittour.com.ua/showcase_search.jsx?id=37267D2848G736581M436&type=103&num=1';
    //     script.id = 'itTourHotPriceWidgetScriptJsx';
    //     document.body.appendChild(script);

    //     setTimeout(() => {
    //         showcase_hottour_v2_showcase_load_css();
    //     }, 2 * 1000);
    // }, []);

    // return <div id="showcase_hottour_v2_showcase_module" />;

    return <div className="hot-tour-container">
        <Iframe className={className} src="/4a925e55dfa8af95a15ba18222c40046aaaccbf47b3c8714ba25014370770027" />
    </div>;
}

HotTourWidget.defaultProps = {
    className: 'iframe-hot-tour-preview',
};

export default HotTourWidget;
