import React, { useEffect } from 'react';

import Iframe from 'components/Iframe';

function PartnerSearchWidget({ location: { hash } }) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return <section className="relative">
        <div className="container mx-auto">
            <div className="search-tour-container">
                <Iframe className="iframe-search-tour-overview" src={`/9573605921c8c51780963ad58fe318568c65f2898b989e77b3ef0a291d746633${hash}`} />
            </div>
        </div>
    </section>;
}

PartnerSearchWidget.defaultProps = {
    location: {
        hash: null,
    },
};

export default PartnerSearchWidget;
