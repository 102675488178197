import React from 'react';

import Iframe from 'components/Iframe';

function SearchWidget({ windowWidth }) {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://www.ittour.com.ua/tour_search.jsx?id=154701DG73254524516M8&ver=3';
    //     script.id = 'itTourWidgetScriptJsx';
    //     document.body.appendChild(script);
    // }, []);

    // return <div id="itTourWidgetWrapper"></div>;

    return <div className="search-tour-container">
        <Iframe className="iframe-search-tour-preview" scrolling={windowWidth >= 1280 ? 'no' : 'yes'} src="/1f5d0d747d04e1438abf0d5ee3ecafaf454a0050a602f0193d2ba1d7112752a0" />;
    </div>;
}

SearchWidget.defaultProps = {
    windowWidth: 0,
};


export default SearchWidget;
