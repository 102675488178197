import React, { useEffect } from 'react';

import HotTourWidget from 'components/HotTourWidget';

function PartnerOffersWidget() {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return <section className="relative">
        <div className="container mx-auto">
            <HotTourWidget className="iframe-hot-tour-overview" />
        </div>
    </section>;
}

export default PartnerOffersWidget;
