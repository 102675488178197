import React from 'react';
import { Drawer, List, ListItem, ListItemText, Link, MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { ApiGet } from 'common/Api';
import { PageEndpoint, Stringify, TourEndpoint } from 'common/Endpoint';
// import { GetCurrentLanguage, LanguageList } from 'common/Language';
import { Translation } from 'common/Translation';
import Social from 'components/Social';

function Header({ about, changeLanguage, contact, isMobile, hideLoading, name, setInitialDetails, showLoading, tour_types }) {
    const history = useHistory();

    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [tourMenu, setTourMenu] = React.useState(null);

    const handleShowUrl = (pathname, query) => (event) => {
        history.push({
            pathname,
            search: Stringify(query),
        });
        event.preventDefault();

        setNavbarOpen(false);
    };

    const handleOpenTourMenu = (event) => {
        setTourMenu(event.currentTarget);
        event.preventDefault();
    };

    const handleCloseTourMenu = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setTourMenu(null);
    };

    // const handleSelectLanguage = (event) => {
    //     showLoading();
    //     history.push('/');
    //     changeLanguage(event.target.value);

    //     ApiGet(`initial?mobile=${isMobile ? 1 : 0}`).then((response) => {
    //         hideLoading();
    //         if (response.status) setInitialDetails(response.data);
    //     });
    // };

    // const handleClickLanguage = (value) => (event) => {
    //     event.target.value = value;
    //     handleSelectLanguage(event);
    // };

    return (
        <nav className="w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                    <Link className="max-w-200-px inline-block py-2" onClick={handleShowUrl('/')} href="/">
                        <img src={require('assets/img/logo-a.png')} alt={name} />
                    </Link>
                    <button
                        className="cursor-pointer text-3xl block lg:hidden outline-none focus:outline-none"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
                <div
                    className={'lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none' + (navbarOpen ? ' block' : ' hidden ml-8')}
                >
                    {/* <ul className="flex flex-col lg:flex-row list-none lg:ml-auto ">
                        <li className="flex items-center item-select-language">
                            <ul className="lg:hidden">
                                {LanguageList.map((key) => (
                                    <MenuItem className="select-language" value={key} key={key} onClick={handleClickLanguage(key)}>
                                        <img src={require(`assets/img/flags/${key}.png`)} alt={key} />
                                    </MenuItem>
                                ))}
                            </ul>
                        </li>
                    </ul> */}
                    <Social mode="lg" />
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:mr-4">
                        {about && (
                            <li className="flex items-center">
                                <Link
                                    color="secondary"
                                    underline="none"
                                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                                    onClick={handleShowUrl(PageEndpoint(about.slug))}
                                    href={PageEndpoint(about.slug)}
                                >
                                    {Translation({ variable: 'general.about' })}
                                </Link>
                            </li>
                        )}
                        <li className="flex items-center">
                            <Link
                                color="secondary"
                                underline="none"
                                className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                                onClick={handleOpenTourMenu}
                                href={TourEndpoint()}
                            >
                                {Translation({ variable: 'general.offers' })}
                            </Link>
                            {tour_types && (
                                <Drawer anchor="right" open={Boolean(tourMenu)} onClose={handleCloseTourMenu}>
                                    <div onClick={handleCloseTourMenu} onKeyDown={handleCloseTourMenu}>
                                        <List>
                                            <Link
                                                color="primary"
                                                underline="none"
                                                // onClick={handleShowUrl(TourEndpoint('search'))}
                                                href={TourEndpoint('search')}
                                            >
                                                <ListItem button>
                                                    <ListItemText primary={Translation({ variable: 'general.partner_search' })} />
                                                </ListItem>
                                            </Link>
                                            <Link
                                                color="primary"
                                                underline="none"
                                                // onClick={handleShowUrl(TourEndpoint('special'))}
                                                href={TourEndpoint('special')}
                                            >
                                                <ListItem button>
                                                    <ListItemText primary={Translation({ variable: 'general.partner_offers' })} />
                                                </ListItem>
                                            </Link>
                                            <Link
                                                color="secondary"
                                                underline="none"
                                                onClick={handleShowUrl(TourEndpoint(null), { vid: [15], hasbeenchanged:true })}
                                                href={TourEndpoint(null, { vid: [15] })}
                                            >
                                                <ListItem button>
                                                    <ListItemText primary={Translation({ variable: 'tour.vid.15' })} />
                                                </ListItem>
                                            </Link>
                                            {Object.keys(tour_types).map((key) => (
                                                <Link
                                                    color="secondary"
                                                    key={tour_types[key]}
                                                    underline="none"
                                                    onClick={handleShowUrl(TourEndpoint(key == 1 ? 'places' : null), key == 1 ? { type: [key] } : { type: [key], hasbeenchanged:true })}
                                                    href={TourEndpoint(key == 1 ? 'places' : null, { type: [key] })}
                                                >
                                                    <ListItem button>
                                                        <ListItemText primary={tour_types[key]} />
                                                    </ListItem>
                                                </Link>
                                            ))}
                                        </List>
                                    </div>
                                </Drawer>
                            )}
                        </li>
                        {contact && (
                            <li className="flex items-center">
                                <Link
                                    color="secondary"
                                    underline="none"
                                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                                    onClick={handleShowUrl(PageEndpoint(contact.slug))}
                                    href={PageEndpoint(contact.slug)}
                                >
                                    {Translation({ variable: 'general.contacts' })}
                                </Link>
                            </li>
                        )}
                        <li className="flex items-center">
                            <Link
                                color="secondary"
                                underline="none"
                                className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                                target="_blank"
                                href="https://www.google.com/maps/place/@49.233605,28.46457,17z/data=!4m7!3m6!1s0x472d5b7b5f2ab499:0xd6e54f29217b78ea!8m2!3d49.2335117!4d28.4667209!9m1!1b1"
                            >
                                {Translation({ variable: 'general.reviews' })}
                            </Link>
                        </li>
                    </ul>
                    {/* <ul className="flex flex-col lg:flex-row list-none">
                        <li className="flex items-center item-select-language">
                            <Select value={GetCurrentLanguage()} onChange={handleSelectLanguage} className="lg:visible">
                                {LanguageList.map((key) => (
                                    <MenuItem className="select-language" value={key} key={key}>
                                        <img src={require(`assets/img/flags/${key}.png`)} alt={key} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </li>
                    </ul> */}
                    <Social mode="sm" />
                </div>
            </div>
        </nav>
    );
}

Header.defaultProps = {
    about: {
        slug: null,
    },
    changeLanguage: () => {},
    contact: {
        slug: null,
    },
    isMobile: false,
    hideLoading: () => {},
    name: null,
    setInitialDetails: () => {},
    showLoading: () => {},
    tour_types: {},
};

export default Header;
