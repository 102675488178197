import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Banner from 'components/Banner';
import Subscription from 'components/Subscription';
import { Translation } from 'common/Translation';

function PopupBlock({ file, title, type, url }) {
    const timestamp = Math.floor(Date.now() / 1000);
    const [open, setOpen] = useState(false);
    const [bannerBlock, setBannerBlock] = useState(false);
    const [subscriptionBlock, setSubscriptionBlock] = useState(false);

    const handleClose = () => {
        if (subscriptionBlock) {
            setSubscriptionBlock(false);
            localStorage.setItem('popup_subscription', timestamp);
        } else {
            setBannerBlock(false);
            localStorage.setItem('popup_banner', file);
        }

        setOpen(false);
    };

    const handleSubscription = (response) => {
        if (response.status) {
            handleClose();
        }
    };

    useEffect(() => {
        if (file && file !== localStorage.getItem('popup_banner')) {
            setBannerBlock(true);
            setOpen(true);
        // } else if (localStorage.getItem('subscribed')) {
        //     handleClose();
        // } else if (
        //     !localStorage.getItem('popup_subscription') ||
        //     (localStorage.getItem('popup_subscription') && Math.floor((timestamp - localStorage.getItem('popup_subscription')) / 86400) >= 1)
        // ) {
        //     setSubscriptionBlock(true);
        }
    }, [localStorage.getItem('popup_banner'), localStorage.getItem('popup_subscription'), localStorage.getItem('subscribed')]);

    if (subscriptionBlock && !open) {
        const timer = setInterval(function () {
            if (Math.floor(Date.now() / 1000) - timestamp >= 15) {
                setOpen(true);
                clearInterval(timer);
            }
        }, 1000);
    }

    return (
        (subscriptionBlock || bannerBlock) && (
            <Dialog maxWidth={subscriptionBlock ? 'md' : 'xl'} open={open} onClose={handleClose}>
                <DialogContent className={subscriptionBlock ? 'p-0' : ''}>
                    {subscriptionBlock && (
                        <div className="subscription_popup_block">
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                style={{
                                    position: 'absolute',
                                    right: '0.25rem',
                                    top: '0.25rem',
                                    color: 'white',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 bg-green-600">
                                    <div className="p-8 px-10">
                                        <div className="flex">
                                            <div className="md:w-3/12">
                                                <img className="float-right mr-4" src={require('assets/img/newsletter/email.png')} alt="" />
                                            </div>
                                            <div className="md:w-9/12">
                                                <h5 className="mb-2 text-white">
                                                    {Translation({ variable: 'general.subscribe_to_email_newsletter', values: {}, html: true })}
                                                </h5>
                                                <Subscription view="popup" type="email" callback={handleSubscription} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 bg-gray-900">
                                    <div className="p-8 px-10">
                                        <div className="flex">
                                            <div className="md:w-3/12">
                                                <img className="float-right mr-4" src={require('assets/img/newsletter/sms.png')} alt="" />
                                            </div>
                                            <div className="md:w-9/12">
                                                <h5 className="mb-2 text-white">
                                                    {Translation({ variable: 'general.subscribe_to_sms_newsletter', values: {}, html: true })}
                                                </h5>
                                                <Subscription view="popup" type="phone" callback={handleSubscription} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 bg-gray-100">
                                    <div className="p-8 px-10">
                                        <div className="flex">
                                            <div className="md:w-3/12">
                                                <img className="float-right mr-4" src={require('assets/img/newsletter/viber.png')} alt="" />
                                            </div>
                                            <div className="md:w-9/12">
                                                <h5 className="mb-2">
                                                    {Translation({ variable: 'general.subscribe_to_viber', values: {}, html: true })}
                                                </h5>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    target="_blank"
                                                    className="bg-red-600 text-white active:bg-red-700 px-6 py-3 capitalize"
                                                    href="https://invite.viber.com/?g2=AQB337sXp%2F8MFEhJDF1of5qDzyAwHiUZXmk8hZipazq7gO3L95GfTPt6J9qQwhlj&lang=uk"
                                                >
                                                    {Translation({ variable: 'general.subscribe' })}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 bg-gray-100">
                                    <div className="p-8 px-10">
                                        <div className="flex">
                                            <div className="md:w-3/12">
                                                <img className="float-right mr-4" src={require('assets/img/newsletter/telegram.png')} alt="" />
                                            </div>
                                            <div className="md:w-9/12">
                                                <h5 className="mb-2">
                                                    {Translation({ variable: 'general.subscribe_to_telegram', values: {}, html: true })}
                                                </h5>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    target="_blank"
                                                    className="bg-red-600 text-white active:bg-red-700 px-6 py-3 capitalize"
                                                    href="tg://resolve?domain=lovetravelvn"
                                                >
                                                    {Translation({ variable: 'general.subscribe' })}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {bannerBlock && (
                        <div className="p-5">
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                style={{
                                    position: 'absolute',
                                    right: '0.25rem',
                                    top: '0.25rem',
                                    color: (theme) => theme.palette.grey[700],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Banner file={file} title={title} type={type} url={url} />
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        )
    );
}

PopupBlock.defaultProps = {
    title: null,
    url: '/',
    type: 1,
    file: null,
};

export default PopupBlock;
