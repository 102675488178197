import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { GetCurrentLanguage } from 'common/Language';
import Home from 'views/Home';
import HotelOverview from 'views/HotelOverview';
import NewsList from 'views/NewsList';
import NewsOverview from 'views/NewsOverview';
import NotFound from 'components/NotFound';
import PageOverview from 'views/PageOverview';
import PartnerOffersWidget from 'views/PartnerOffersWidget';
import PartnerSearchWidget from 'views/PartnerSearchWidget';
import TourOverview from 'views/TourOverview';
import TourPlaces from 'views/TourPlaces';
import ToursList from 'views/ToursList';

const ScrollToTop = withRouter(({ history }) => {
    React.useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
});

function Router({ language }) {
    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route path={`/${language}/news`} component={NewsList} exact />
                <Route path={`/${language}/news/:slug`} component={NewsOverview} exact />
                <Route path={`/${language}/places/:country/:city/:hotel`} component={HotelOverview} exact />
                <Route path={`/${language}/tours`} component={ToursList} exact />
                <Route path={`/${language}/tours/special`} component={PartnerOffersWidget} exact />
                <Route path={`/${language}/tours/search`} component={PartnerSearchWidget} exact />
                <Route path={`/${language}/tours/places`} component={TourPlaces} exact />
                <Route path={`/${language}/tours/:slug`} component={TourOverview} exact />
                <Route path={`/${language}/:slug`} component={PageOverview} exact />
                <Route path={`/${language}`} component={Home} exact />
                <Route path="/" component={Home} exact />
                <Route path="*" component={NotFound} />
            </Switch>
        </>
    );
}

Router.defaultProps = {
    language: GetCurrentLanguage(),
};

export default Router;
