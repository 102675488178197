import { connect } from 'react-redux';

import SearchWidget from './SearchWidget';

const mapStateToProps = (state) => {
    const {
        Global: { windowWidth },
    } = state;

    return { windowWidth };
};

export default connect(mapStateToProps, {})(SearchWidget);

