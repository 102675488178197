import React from 'react';

function Iframe({ className, height, scrolling, src, title, width }) {
    return (
        <iframe
            className={className}
            height={height}
            src={src}
            title={title}
            width={width}
            scrolling={scrolling}
        ></iframe>
    );
}

Iframe.defaultProps = {
    className: 'iframe-block',
    height: '100%',
    scrolling: 'yes',
    src: null,
    title: null,
    width: '100%',
};

export default Iframe;
